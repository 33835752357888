import {
  Text,
  Tabs,
  TabPanels,
  TabPanel,
  Tab,
  TabList,
  HStack,
  Button,
  GridItem,
  SimpleGrid,
} from '../../../components';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import { useModal } from '../../../hooks';
import { TreatmentPlan } from './TreatmentPlan';
import { useGetEncounterOrders } from './useGetEncounterOrders';
import { CDSSRecommendations } from './CDSSRecommendations';
import { useState } from 'react';
import { CircleNumber } from './CircleNumber';

export function CDSSContent() {
  const { showModal } = useModal();
  const [activeIndex, setActiveIndex] = useState(0);

  const { numberOfOrders } = useGetEncounterOrders();

  const saveHandler = async () => {
    showModal({
      modalType: 'AddAdHocOrderModal',
      chakraModalProps: { size: '6xl' },
      modalProps: {},
    });
  };

  return (
    <MAWorkFlowContentContainer>
      <Tabs
        colorScheme='brand'
        mt='md'
        index={activeIndex}
        onChange={(index) => {
          setActiveIndex(index);
        }}>
        <SimpleGrid columns={3} alignItems='center' w='full'>
          <GridItem colSpan={1}>
            <Text variant='h6-b'>Treatment Plan + CDSS</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <TabList maxW={450} mx='auto'>
              <Tab>CDSS Recommendations</Tab>
              <Tab>
                <HStack>
                  <Text>Treatment Plan</Text>
                  {numberOfOrders && <CircleNumber value={numberOfOrders} />}
                </HStack>
              </Tab>
            </TabList>
          </GridItem>
          <GridItem display={'flex'} justifyContent={'end'} colSpan={1}>
            <Button ml='auto' onClick={saveHandler}>
              Add Order
            </Button>
          </GridItem>
        </SimpleGrid>

        <TabPanels>
          <TabPanel>
            <CDSSRecommendations
              onAddToTreatmentPlanSuccess={() => {
                setActiveIndex(1);
              }}
            />
          </TabPanel>
          <TabPanel>
            <TreatmentPlan />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </MAWorkFlowContentContainer>
  );
}
