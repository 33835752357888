import { useState } from 'react';
import { useCustomToast, usePatient, useMAWorkflow } from '../../../hooks';
import {
  selectors,
  useAppDispatch,
  useAppSelector,
  worklistTodoActions,
} from '../../../state-management';
import {
  getOrderableItemReasonFromRecommendationType,
  isAcceptingEditedOrder,
  isDecliningEditedOrder,
  isRecommendationInjectionOrImmunization,
  isRecommendationLab,
} from '../../../utils';
import { IS_LOCAL } from '../../../constants';
import {
  type AddOrderableItemMutationVariables,
  OrderableItemOrderType,
  OrderDeclineReasonCode,
  useAddOrderableItemMutation,
  useDeclineOrderableImmunizationInjectionMutation,
  useDeclineOrderableItemMutation,
  useSubmitImmunizationInjectionMutation,
} from '../../../__generated__/graphql';
import { isNumber } from 'lodash';
import { useApolloClient } from '@apollo/client';

export function useAddEditedOrdersToTreatmentPlan() {
  const client = useApolloClient();
  const { isMAWorkflowEnabled } = useMAWorkflow();
  const dispatch = useAppDispatch();
  const [submitImmunization] = useSubmitImmunizationInjectionMutation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [handleAddOrderableItem] = useAddOrderableItemMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleDeclineOrderableItem] = useDeclineOrderableItemMutation();
  const [handleDeclineImmunizationInjection] = useDeclineOrderableImmunizationInjectionMutation();
  const toast = useCustomToast();

  const dispatchScrollToTopEvent = () => {
    // we don't have access to the ref required to scroll to the top of the MA Workflow content container
    // unless we want to drill the ref down through several components
    // so instead we dispatch a custom event that the MAWorkflowScreen can listen for
    window.dispatchEvent(new Event('scrollToTopOfMAWorkFlowContentContainer'));
  };

  function handleShowErrorToast(orderableItemName?: string | null) {
    if (orderableItemName) {
      toast({
        id: `submission-error-toast-${orderableItemName}`,
        title: 'Error',
        description: `${orderableItemName} failed to submit`,
        status: 'error',
        duration: null,
      });
    }
  }
  function handleShowClientErrorToast(orderableItemName?: string | null) {
    if (orderableItemName) {
      toast({
        id: `submission-error-toast-${orderableItemName}`,
        title: 'Error',
        description: `${orderableItemName} failed to submit due to missing form values`,
        status: 'error',
        duration: null,
      });
    }
  }
  function handleShowSuccessToast() {
    toast({
      id: 'submission-success-toast',
      title: 'Success',
      description: 'Submitted successfully',
      status: 'success',
    });
  }

  const editedOrders = useAppSelector(selectors.editedOrdersSelectors.selectAll);
  const { patient } = usePatient();
  const facilityID = useAppSelector(selectors.getSelectedLocationID);
  const encounterID = useAppSelector(selectors.encounterSelectors.getActiveEncounterID);
  const orderingProviderID = useAppSelector(selectors.getActiveProviderID);
  const patientId = patient?.patientID;

  async function handleAddEditedOrdersToTreatmentPlan({
    onAddToTreatmentPlanSuccess,
  }: {
    onAddToTreatmentPlanSuccess?: () => void;
  }) {
    const handleAddOrderPromises = editedOrders.map(async (order) => {
      const orderableItemTypeId = order?.alert?.orderableItemTypeId;
      const isAcceptingOrder = isAcceptingEditedOrder(order);
      const recommendation = order?.alert;
      const orderableItemOrderType =
        order?.alert.orderableItemOrderType || OrderableItemOrderType.Recommended;
      const futureOrder = recommendation?.futureOrder;
      const isInjectionOrImmunization = isRecommendationInjectionOrImmunization(order);
      const assignedToUserEmail = IS_LOCAL ? 'tdale@elfp.com' : 'jbaker@elfp.com'; // TODO: Real user email
      const documentingFacilityID = facilityID;
      const selectedOrderableItem = order.selectedOrderableItem;
      const orderableItemId = selectedOrderableItem?.orderableItemId
        ? selectedOrderableItem.orderableItemId
        : recommendation?.orderableItemId;
      const orderableItemName = selectedOrderableItem?.orderableItemName
        ? selectedOrderableItem.orderableItemName
        : recommendation?.orderableItemName;
      const labItemId = orderableItemId; // Normal order
      const immunizationItemID = orderableItemId; // Immunization order
      const patientID = patientId;
      const recommendationType = recommendation?.recommendationType;
      const declineReason = order.declineReason;
      const declineReasonCode = declineReason?.serverDeclineReasonCode;
      // TODO: Properly type this.
      const patientImmunizationInjectionType: any = recommendation?.recommendationType;
      const assessmentItemId = order?.diagnosis?.assessmentItemId;

      const alreadyOrderedLocation = declineReason?.location;
      const alreadyOrderedDate = declineReason?.dateTimeOrdered;
      const alreadyOrderedResult = declineReason?.result;
      const alreadyOrderedNotes = declineReason?.additionalDetails;

      if (isAcceptingOrder) {
        // HANDLE ACCEPTED ORDERS
        if (isInjectionOrImmunization) {
          // PROCESS IMMUNIZATION ORDER
          const canAddOrder =
            assignedToUserEmail &&
            documentingFacilityID &&
            encounterID &&
            immunizationItemID &&
            patientID &&
            patientImmunizationInjectionType;

          if (canAddOrder) {
            return await submitImmunization({
              variables: {
                input: {
                  sourceEncounterId: encounterID,
                  immunizationItemId: immunizationItemID,
                  immunizationName: orderableItemName,
                  statusId: 3, // "Not Administered"
                  sourcePatientId: patientID,
                  documentingFacilityId: documentingFacilityID,
                  patientImmunizationInjectionType,
                  lotId: 0,
                },
              },
              onError: () => {
                handleShowErrorToast(orderableItemName);
              },
              onCompleted: (res) => {
                if (isMAWorkflowEnabled) {
                  const immunizationResponse = res.submitImmunizationInjection;
                  dispatch(
                    worklistTodoActions.addPendingVaccine({
                      id: orderableItemId,
                      text: orderableItemName ?? '',
                      completed: false,
                      immunization: immunizationResponse,
                    }),
                  );
                }
                dispatchScrollToTopEvent(); // Add this line
              },
            });
          }
        } else {
          // PROCESS NON-IMMUNIZATION ORDER
          const canAddOrder =
            assessmentItemId &&
            labItemId &&
            patientId &&
            facilityID &&
            encounterID &&
            isNumber(orderableItemTypeId) && // Could be "0"
            orderingProviderID;

          if (canAddOrder) {
            const requestParams: AddOrderableItemMutationVariables = {
              assessmentItemId,
              labItemId,
              assignedToUserEmail,
              encounterID,
              orderDate: new Date().toISOString(), // TODO: server-side?
              orderableItemTypeId:
                orderableItemTypeId !== null && isNumber(orderableItemTypeId)
                  ? orderableItemTypeId
                  : -1, // TODO: Server added?
              orderingProviderID,
              patientId,
              facilityID,
              futureOrder,
              orderableItemOrderType,
              reason: futureOrder
                ? ''
                : getOrderableItemReasonFromRecommendationType(recommendationType),
            };
            return await handleAddOrderableItem({
              variables: requestParams,
              onError: () => {
                handleShowErrorToast(orderableItemName);
              },
              onCompleted: (res) => {
                if (isMAWorkflowEnabled && isRecommendationLab(order)) {
                  const orderableReportID = res.addOrderableItem?.orderableReportID;

                  if (orderableReportID && !futureOrder) {
                    dispatch(
                      worklistTodoActions.updateLabItem({
                        orderableReportID,
                        orderableItemId,
                        orderableItemName: orderableItemName ?? '',
                      }),
                    );
                  }
                }
                dispatchScrollToTopEvent(); // Add this line
              },
            });
          } else {
            handleShowClientErrorToast(orderableItemName);
          }
        }
      } else if (isDecliningEditedOrder(order)) {
        // HANDLE DECLINED ORDERS
        if (isInjectionOrImmunization) {
          const canAddOrder =
            assignedToUserEmail &&
            documentingFacilityID &&
            encounterID &&
            immunizationItemID &&
            patientID &&
            declineReasonCode &&
            patientImmunizationInjectionType;

          if (canAddOrder) {
            const requestParams = {
              assignedToUserEmail,
              documentingFacilityID,
              encounterID,
              immunizationItemID,
              patientID,
              declineReasonCode: declineReasonCode || OrderDeclineReasonCode.AlreadyOrdered,
              patientImmunizationInjectionType,
              orderableItemOrderType,
              lotId: 0,
            };

            return await handleDeclineImmunizationInjection({
              variables: requestParams,
              onError: () => {
                handleShowErrorToast(orderableItemName);
              },
              onCompleted: () => {
                // Add this callback
                dispatchScrollToTopEvent();
              },
            });
          }
        } else {
          // TODO: Handle non-immunization decline
          const canAddOrder =
            assignedToUserEmail &&
            encounterID &&
            facilityID &&
            labItemId &&
            patientId &&
            orderingProviderID &&
            declineReasonCode;

          if (canAddOrder) {
            const requestParams = {
              declineReasonCode,
              assignedToUserEmail,
              encounterID,
              facilityID,
              labItemId,
              futureOrder,
              patientId,
              orderableItemTypeId: isNumber(orderableItemTypeId) ? orderableItemTypeId : -1, // TODO: Server added?
              orderDate: new Date().toISOString(), // TODO: server-side?
              orderingProviderID,
              // Already ordered use-case
              alreadyOrderedLocation,
              alreadyOrderedDate,
              alreadyOrderedResult,
              alreadyOrderedNotes,
              orderableItemOrderType,
              lotId: 0,
            };

            return await handleDeclineOrderableItem({
              variables: requestParams,
              onError: () => {
                handleShowErrorToast(orderableItemName);
              },
              onCompleted: () => {
                // Add this callback
                dispatchScrollToTopEvent();
              },
            });
          }
        }
      }
    });

    try {
      setIsSubmitting(true);
      const results = [];
      for (const promise of handleAddOrderPromises) {
        const result = await promise; // Wait for each promise to resolve sequentially
        results.push(result); // Accumulate results
      }
      const hasError = results.some((r) => !!r?.errors);

      if (!hasError) {
        client.cache.evict({ fieldName: 'getEncounterOrders' });
        onAddToTreatmentPlanSuccess?.();
        handleShowSuccessToast();
        setIsSuccess(true);
      }
    } catch (e) {
      toast({
        id: 'submit-orders-catch-error',
        title: 'Error',
        description: `Something went wrong. ${e}`,
        status: 'error',
        duration: null,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return {
    handleAddEditedOrdersToTreatmentPlan,
    isSubmitting,
    isSuccess,
  };
}
