import {
  // type EncounterOrderableItem,
  type Assessment,
  useAssessmentsQuery,
} from '../../__generated__/graphql';
import { useEncounter, usePatient } from '../../hooks';
import { type DiagnosisUI } from '../../types';
import { useEffect, useState } from 'react';

function sortDesc(a: any, b: any) {
  if (a.diagnosisName > b.diagnosisName) {
    return 1;
  }
  if (a.diagnosisName < b.diagnosisName) {
    return -1;
  }
  return 0;
}

export function usePatientAssessments({
  orderableItemAssessments,
}: {
  orderableItemAssessments?: Array<
    Pick<Assessment, 'assessmentId' | 'assessmentName' | 'assessmentValue'>
  >;
}) {
  const { activeEncounterID } = useEncounter();
  const { patientId, patient: data, loading: isLoadingPatient } = usePatient();

  const { data: dataAssessments } = useAssessmentsQuery({
    variables: {
      filter: {
        patientId,
        encounterId: activeEncounterID,
      },
    },
  });
  const [diagnosisList, setDiagnosisList] = useState<DiagnosisUI[]>([]);

  // initialize diagnosisList and put in state
  const problems = data?.problems;
  const assessments = dataAssessments?.getAssessments?.assessments;

  useEffect(() => {
    let diagnosisList: DiagnosisUI[] = [];
    const problems = data?.problems ?? [];
    const assessments = dataAssessments?.getAssessments?.assessments ?? [];

    const diagnosisListFromAssessments = assessments.map((a) => {
      return {
        diagnosisName: a.assessmentName ?? '',
        icdCode: a.assessmentCode ?? '',
        assessmentItemId: a.assessmentItemId,
      };
    });

    const diagnosisListFromProblems = problems.map((p) => {
      return {
        diagnosisName: p.problemName || undefined,
        icdCode: p.icdCode || undefined,
        assessmentItemId: p.assessmentItemId || undefined,
      };
    });

    const combinedDiagnosisList: DiagnosisUI[] = [
      ...diagnosisListFromAssessments,
      ...diagnosisListFromProblems,
    ];

    // remove duplicates where assessmentItemId is the same
    const diagnosisMap: Record<number, DiagnosisUI> = combinedDiagnosisList.reduce(
      (acc: Record<number, DiagnosisUI>, curr) => {
        if (curr.assessmentItemId) {
          acc[curr.assessmentItemId] = curr;
        }
        return acc;
      },
      {},
    );

    // Initialize addedAssessments with the assessments that are already present in the order
    let initialAssessments: DiagnosisUI[] = [];
    if (orderableItemAssessments) {
      initialAssessments = orderableItemAssessments.map((a) => {
        return {
          diagnosisName: a.assessmentName ?? '',
          icdCode: a.assessmentValue ?? '',
          assessmentItemId: a.assessmentId ?? 0,
          isSelected: true,
        };
      });
    }

    diagnosisList = [...initialAssessments, ...Object.values(diagnosisMap).sort(sortDesc)];

    // new set by removing duplicates based on assessmentItemId
    diagnosisList = Array.from(new Set(diagnosisList.map((a) => a.assessmentItemId))).map(
      (assessmentItemId) => {
        return diagnosisList.find((a) => a.assessmentItemId === assessmentItemId) ?? {};
      },
    );

    setDiagnosisList(diagnosisList);
  }, [problems, assessments]);

  return {
    diagnosisList,
    isLoading: isLoadingPatient,
    setDiagnosisList,
    problems,
    assessments,
  };
}
