import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  FormControl,
  HStack,
  ModalCloseButton,
  ModalHeader,
  WaitUntilLoaded,
} from '../../index';
import {
  Checkbox,
  Flex,
  ModalBody,
  ModalFooter,
  Tab,
  TabList,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { useCustomToast, useEncounter, useModal, usePatient } from '../../../hooks';
import { AsyncSelectField } from '../../../features';
import { type DiagnosisUI } from '../../../types';
import { Text } from '../../typography';
import { SearchIcon } from '@chakra-ui/icons';
import {
  convertInjectionLookupItemToAsyncDropdownOptions,
  convertOrderableItemsToAsyncDropdownOptions,
  createManualImmunizationOrderableItem,
  createManualOrderableItem,
  useAddOrder,
} from './adhoc-order.helper';
import {
  ImmunizationInjectionType,
  useGetEncounterOrdersQuery,
  useGetImmunizationInjectionsQuery,
  useGetOrderableItemsLookupQuery,
} from '../../../__generated__/graphql';
import { PatientAssessmentSelect } from '../../../features/PatientAssessmentSelect';

type TabType = 'All' | 'Lab' | 'Diagnostic Imaging' | 'Procedure' | 'Injection';
const tabs: Array<{ title: string; type: TabType }> = [
  {
    title: 'All',
    type: 'All',
  },
  {
    title: 'Labs',
    type: 'Lab',
  },
  {
    title: 'Imaging',
    type: 'Diagnostic Imaging',
  },
  {
    title: 'Procedures',
    type: 'Procedure',
  },
  {
    title: 'Immunizations / T. Injections',
    type: 'Injection',
  },
];

export function AddAdHocOrderModal() {
  const [selectedTab, setSelectedTab] = useState<TabType | null>('All');

  const { loading: isLoading } = usePatient();
  const { isLoading: isLoadingAddOrder, handleAddOrder } = useAddOrder();
  const { hideModal } = useModal();
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [selectedDropdownItem, setSelectedOrderableItem] = useState<
    DropdownOption | undefined | null
  >();
  const toast = useCustomToast();

  const [orderState, setOrderState] = useState({
    fasting: false,
    futureOrder: false,
  });
  const isImmunizaationInjectionTab = selectedTab === 'Injection';
  const searchRef = useRef<{ reset: () => void }>();

  const isRegularOrderTab =
    selectedTab === 'Lab' || selectedTab === 'Diagnostic Imaging' || selectedTab === 'Procedure';

  const { data: orderableItemsData, loading: loadingSearch } = useGetOrderableItemsLookupQuery({
    variables: {
      filter: {
        name: searchTerm,
        ...(isRegularOrderTab ? { types: [selectedTab] } : {}),
      },
    },
    skip: !searchTerm,
  });

  const { activeEncounterID } = useEncounter();
  const { data: encounterOrdersData } = useGetEncounterOrdersQuery({
    variables: {
      encounterId: activeEncounterID,
    },
  });
  const existingOrderableItems =
    encounterOrdersData?.getEncounterOrders?.encounterOrderableItems ?? [];
  const existingImmunizationInjectionItems =
    encounterOrdersData?.getEncounterOrders?.encounterImmunizationInjectionItems ?? [];

  const onOrderChange = ({ fasting, futureOrder }: { fasting: boolean; futureOrder: boolean }) => {
    setOrderState({ fasting, futureOrder });
  };

  const { data: injectionData, loading: getImmunizationInjectionsLoading } =
    useGetImmunizationInjectionsQuery({
      variables: {
        contains: searchTerm ?? '',
      },
      skip: !searchTerm || isRegularOrderTab,
    });

  const immunizationInjectionLookupData = injectionData?.getImmunizationInjections ?? [];
  const injectionDropdownItems = convertInjectionLookupItemToAsyncDropdownOptions(
    immunizationInjectionLookupData,
  );

  const selectedInjectionItem = immunizationInjectionLookupData.find(
    (item) => item.immunizationItemId === Number(selectedDropdownItem?.value),
  );

  const orderableItemDropdowmItems = convertOrderableItemsToAsyncDropdownOptions(
    orderableItemsData?.orderableItemsLookup,
  );

  const [selectedAssessments, setSelectedAssessments] = useState<DiagnosisUI[]>([]);
  const hasAssessment = selectedAssessments.length > 0;
  const hasSelectedOrder = !!selectedDropdownItem;

  let dropdownItems = [];
  if (isImmunizaationInjectionTab) {
    // Immunization / Injection Tab
    dropdownItems = injectionDropdownItems;
  } else if (isRegularOrderTab) {
    // Prpcedure, Lab, Diagnostic Imaging Tabs
    dropdownItems = orderableItemDropdowmItems;
  } else {
    // All tab
    dropdownItems = orderableItemDropdowmItems.concat(injectionDropdownItems);
  }

  const isImmunization = selectedInjectionItem?.type === ImmunizationInjectionType.Immunization;
  const isSelectedDropdownItemImmunizationInjection = !!selectedInjectionItem;

  const saveHandler = () => {
    const selectedItem = orderableItemsData?.orderableItemsLookup.find(
      (item) => item.sourceId === selectedDropdownItem?.value,
    );

    const { futureOrder, fasting } = orderState;
    const isInjection = selectedInjectionItem?.type === ImmunizationInjectionType.Injection;

    // Prevent adding duplicate orders
    const hasAddedOrder = existingOrderableItems.some(
      (oi) =>
        oi.labItemId === Number(selectedDropdownItem?.value) && oi.futureOrder === futureOrder,
    );
    const hasAddedImmunizationInjection = existingImmunizationInjectionItems.some(
      (oi) => oi.immunizationItemId === Number(selectedDropdownItem?.value),
    );

    if (hasAddedOrder || hasAddedImmunizationInjection) {
      toast({
        title: 'Already exists',
        description: 'You cannot add a duplicate order',
        status: 'warning',
        id: 'order-already-added',
      });

      return;
    }

    const orderAssessments = selectedAssessments.map((a) => {
      return {
        assessmentValue: a.icdCode,
        assessmentId: a.assessmentItemId,
        assessmentName: a.diagnosisName,
      };
    });

    if (isImmunization) {
      const adhocImmunization = createManualImmunizationOrderableItem(selectedInjectionItem);
      handleAddOrder(adhocImmunization, {
        onCompleted: () => {
          hideModal();
        },
      });
    } else if (isInjection && hasAssessment) {
      const adhocImmunization = createManualImmunizationOrderableItem(selectedInjectionItem);

      handleAddOrder(
        { ...adhocImmunization, orderAssessments },
        {
          onCompleted: () => {
            hideModal();
          },
        },
      );
    } else if (selectedItem && hasAssessment) {
      const adHocRecommendation = createManualOrderableItem(selectedItem, futureOrder, fasting);
      handleAddOrder(
        { ...adHocRecommendation, orderAssessments },
        {
          onCompleted: () => {
            hideModal();
          },
        },
      );
    }
  };

  return (
    <WaitUntilLoaded loading={isLoading}>
      <>
        <ModalHeader>Add Order</ModalHeader>
        <ModalCloseButton />
        <ModalBody h='100%'>
          <HStack minH={'70vh'} spacing={'lg'} alignItems='flex-start'>
            <Box flex='1'>
              {isImmunization ? (
                <VStack spacing='lg' align={'flex-start'}>
                  <Text fontWeight='bold'>Assessments</Text>
                  <Card size={'sm'}>
                    <Flex justifyContent='space-between'>
                      <Text variant='body2-b'>Encounter for Immunization</Text>
                      <HStack>
                        <Text variant='body2' color='gray.500'>
                          Z23
                        </Text>
                        <Checkbox isChecked isDisabled />
                      </HStack>
                    </Flex>
                  </Card>
                </VStack>
              ) : (
                <Box maxW='sm'>
                  <PatientAssessmentSelect onChange={setSelectedAssessments} />
                </Box>
              )}
            </Box>

            <VStack flex='2' align={'left'} spacing={'lg'} pb='lg'>
              <Text fontWeight='bold'>Order</Text>
              <VStack spacing='sm' align={'flex-start'}>
                <Tabs
                  variant={'solid-rounded'}
                  colorScheme='brand'
                  onChange={(index) => {
                    setSelectedTab(tabs[index].type);
                    // Reset items on screen
                    setSelectedOrderableItem(undefined);
                    setSearchTerm(''); // prevents extra API search when tabs are changed
                    searchRef.current?.reset();
                  }}>
                  <TabList>
                    {tabs.map((tab) => {
                      return <Tab key={tab.title}>{tab.title}</Tab>;
                    })}
                  </TabList>
                </Tabs>
                <FormControl>
                  <Box mb='md'>
                    <AsyncSelectField
                      inputRightElement={<SearchIcon color='gray.300' />}
                      handleOptionSelect={(option) => {
                        setSelectedOrderableItem(option);
                      }}
                      inputProps={{
                        placeholder: 'Quick Find',
                        autoFocus: true,
                      }}
                      dropdownItems={dropdownItems}
                      handleInputChange={(val) => {
                        if (!val) return;

                        setSearchTerm(val);
                      }}
                      ref={searchRef}
                      loading={loadingSearch || getImmunizationInjectionsLoading}
                    />
                  </Box>
                  {selectedDropdownItem?.label && (
                    <Box borderBottom={'1px solid'} borderBottomColor={'gray.300'} py='lg'>
                      <OrderWithFututureOrderAndFastingToggle
                        isImmunizationInjection={isSelectedDropdownItemImmunizationInjection}
                        orderableItemName={selectedDropdownItem.label}
                        onChange={onOrderChange}
                      />
                    </Box>
                  )}
                </FormControl>
              </VStack>
            </VStack>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button
            ml='auto'
            mr='sm'
            variant='ghost'
            onClick={() => {
              hideModal();
            }}>
            Cancel
          </Button>
          <Button
            isDisabled={(!hasAssessment && !isImmunization) || !hasSelectedOrder}
            onClick={saveHandler}
            isLoading={isLoadingAddOrder}>
            Create Order
          </Button>
        </ModalFooter>
      </>
    </WaitUntilLoaded>
  );
}

function OrderWithFututureOrderAndFastingToggle({
  orderableItemName,
  onChange,
  isImmunizationInjection,
}: {
  orderableItemName: string;
  onChange?: (value: { fasting: boolean; futureOrder: boolean }) => void;
  isImmunizationInjection?: boolean;
}) {
  const [state, setState] = useState({
    fasting: false,
    futureOrder: false,
    // selectAll: false,
  });
  const { fasting, futureOrder } = state;

  function toggleFutureOrder() {
    const futureOrder = !state.futureOrder;
    setState({ ...state, futureOrder });
    onChange?.({ fasting, futureOrder });
  }

  function toggleFasting() {
    const fasting = !state.fasting;
    setState({ ...state, fasting });
    onChange?.({ fasting, futureOrder });
  }

  return (
    <VStack>
      <Flex justifyContent={'space-between'} w='full'>
        <Text variant='h6-b'>{orderableItemName}</Text>

        {!isImmunizationInjection && (
          <HStack spacing={'lg'}>
            <HStack spacing={'md'}>
              <Checkbox isChecked={fasting ?? false} onChange={toggleFasting}>
                <Text whiteSpace='nowrap'>Ordered fasting?</Text>
              </Checkbox>
              <Checkbox isChecked={futureOrder ?? false} onChange={toggleFutureOrder}>
                <Text whiteSpace='nowrap'>Future order</Text>
              </Checkbox>
            </HStack>
          </HStack>
        )}
      </Flex>
    </VStack>
  );
}
