import { Box, Input, InputGroup, InputRightElement, SimpleGrid, Text } from '../../../components';
import { BloodPressurePicker, HeartRatePicker, TempPicker } from '../../../features';
import { useEffect, useState } from 'react';
import { FormControl, FormLabel, NumberInput, NumberInputField } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  getVitalsPanelState,
  selectors,
  uiActions,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { useActiveEncounter, useConstellationRecommendation, useCustomToast } from '../../../hooks';
import {
  type EncounterVital,
  Gender,
  useGetVitalsQuery,
  VitalType,
} from '../../../__generated__/graphql';
import {
  createEmptyVitalValidationMap,
  getVitalValidation,
  validateVital,
} from './vitals.validation';
import { type VitalValidationMap } from './vitals.validation.types';
import { calculateAge } from '../../../utils';

function calculateBMI(weightInPounds: string, heightInInches: string): string {
  const weightInKg = +weightInPounds * 0.453592; // Convert pounds to kilograms
  const heightInMeters = +heightInInches * 0.0254; // Convert inches to meters
  const bmi = weightInKg / (heightInMeters * heightInMeters);
  return bmi.toFixed(2); // Return BMI with two decimal points
}

// used for mapping the vitals response value into a qualifier
// and value since it may be returned as `${qualifier}:${value}`
function getQualifierAndValue(s?: string | null) {
  if (!s?.includes(':')) return { value: s ?? '' };
  const [qualifier, value] = s.split(':');
  return {
    qualifier,
    value,
  };
}

const TOAST_DURATION = 3500;

export const VitalsPanel = ({
  saveVitalsHandler,
  isSavingVitals,
}: {
  saveVitalsHandler: (vitals: EncounterVital[]) => Promise<void>;
  isSavingVitals: boolean;
}) => {
  const cellStyle = { mb: 'sm' };
  const patient = useSelector(selectors.getActivePatient);
  const patientAge = calculateAge(patient?.dateOfBirth || '');
  const state = useAppSelector(getVitalsPanelState);
  const [validationResult, setValidationResult] = useState<VitalValidationMap>(
    createEmptyVitalValidationMap(),
  );
  const { activeEncounterId } = useActiveEncounter();
  const dispatch = useAppDispatch();
  const toast = useCustomToast();
  const { saveHandler, isLoading: isLoadindgConstellation } =
    useConstellationRecommendation(handleSaveVitals);

  const { data } = useGetVitalsQuery({
    variables: { patientId: patient?.patientID ?? 0 },
    skip: !patient?.patientID,
  });

  function handleHeartRateChange(configs: EncounterVital[]) {
    dispatch(uiActions.addHeartRateVitals(configs));
  }
  function handleTempChange(configs: EncounterVital[]) {
    dispatch(uiActions.addTempVitals(configs));
  }
  function vitalChangeDecorator<T>(handler: (config: T) => void) {
    return (config: T) => {
      handler(config);
      dispatch(uiActions.setLastSavedVitals());
    };
  }

  useEffect(() => {
    const vitals = getVitals();
    if (patient?.dateOfBirth) {
      const vitalValidation = getVitalValidation(vitals, patientAge) || {};
      setValidationResult(vitalValidation);
    }
  }, [state]);
  function onBlurHandler(vitalType: VitalType) {
    const errorResult = validationResult[vitalType].first;
    if (errorResult) {
      toast({
        id: vitalType,
        title: errorResult.isWarning ? 'Warning' : 'Error',
        description: errorResult.getErrorMessage(),
        status: errorResult.isWarning ? 'warning' : 'error',
        duration: TOAST_DURATION,
      });
    }
  }

  function handleBloodPressureChange(configs: EncounterVital[]) {
    dispatch(uiActions.addBloodPressureVitals(configs));
  }
  function handleWeightChange(value: string) {
    const config: EncounterVital = {
      type: VitalType.Weight,
      value,
    };
    dispatch(uiActions.setWeightVitals(config));
  }
  function handleHeightChange(value: string) {
    const config: EncounterVital = {
      type: VitalType.Height,
      value,
    };
    dispatch(uiActions.setHeightVitals(config));
  }
  function handleBMIChange(value: string) {
    const config: EncounterVital = {
      type: VitalType.Bmi,
      value,
    };
    dispatch(uiActions.setBMIVitals(config));
  }
  function handleRestRateChange(value: string) {
    const config: EncounterVital = {
      type: VitalType.RespiratoryRate,
      value,
    };
    dispatch(uiActions.setRestRateVitals(config));
  }
  function handleWaistCircChange(value: string) {
    const config: EncounterVital = {
      type: VitalType.WaistCirc,
      value,
    };
    dispatch(uiActions.setWaistCircVitals(config));
  }
  function handlePainScaleChange(value: string) {
    const config: EncounterVital = {
      type: VitalType.Pain,
      value,
    };
    dispatch(uiActions.setPainScaleVitals(config));
  }
  function handleO2VitalsChange(value: string) {
    const config: EncounterVital = {
      type: VitalType.O2,
      value,
    };
    dispatch(uiActions.setO2SatVitals(config));
  }
  function handleLMPChange(value: string | null) {
    const config: EncounterVital = {
      type: VitalType.Lmp,
      value: value ?? '',
    };
    dispatch(uiActions.setLMPVitals(config));
  }

  function getVitals() {
    const vitals = [...state.bloodPressureVitals, ...state.heartRateVitals, ...state.tempVitals];
    if (state.weightVitals?.value) {
      vitals.push(state.weightVitals);
    }
    if (state.heightVitals?.value) {
      vitals.push(state.heightVitals);
    }
    if (state.bmiVitals?.value) {
      vitals.push(state.bmiVitals);
    }
    if (state.restRateVitals?.value) {
      vitals.push(state.restRateVitals);
    }
    if (state.painScaleVitals?.value) {
      vitals.push(state.painScaleVitals);
    }
    if (state.lmpVitals?.value) {
      vitals.push(state.lmpVitals);
    }
    if (state.waistCircVitals?.value) {
      vitals.push(state.waistCircVitals);
    }
    if (state.o2Sat?.value) {
      vitals.push(state.o2Sat);
    }
    return vitals;
  }

  function handleSaveVitals() {
    saveVitalsHandler(getVitals());
  }

  // used for mapping the vitals response into the vitals panel state
  const vitalsArrayMappers = [
    { type: [VitalType.HeartRate], fn: handleHeartRateChange },
    { type: [VitalType.BloodPress], fn: handleBloodPressureChange },
    { type: [VitalType.Temp], fn: handleTempChange },
  ];

  // used for mapping the vitals response into the vitals panel state
  const vitalsMappers = [
    { type: [VitalType.Bmi], fn: handleBMIChange },
    { type: [VitalType.Height], fn: handleHeightChange },
    { type: [VitalType.Lmp], fn: handleLMPChange },
    { type: [VitalType.Pain], fn: handlePainScaleChange },
    { type: [VitalType.RespiratoryRate], fn: handleRestRateChange },
    { type: [VitalType.WaistCirc], fn: handleWaistCircChange },
    { type: [VitalType.Weight], fn: handleWeightChange },
    { type: [VitalType.O2], fn: handleO2VitalsChange },
  ];

  // check if we have any vitals for the active encounter already saved
  // if found, we'll pass those into the vitals panel state
  useEffect(() => {
    const encounterVitals = data?.getVitals
      ?.filter((v) => v.encounter === activeEncounterId)
      ?.map((v) => v.vitals);

    if (!encounterVitals?.length) return;
    const vitalsArray: EncounterVital[] = [];

    // push the response into the EncounterVital[] array
    encounterVitals.forEach((vitals) => {
      Object.entries(vitals).forEach(([key, vals]) => {
        if (!vals) return;
        if (typeof vals !== 'object') return;
        if (!('value' in vals)) return;

        const encounterVital = {
          type: key as VitalType,
          ...getQualifierAndValue(vals.value),
        };

        vitalsArray.push(encounterVital);
      });
    });

    // handle the vitals that may have qualifiers
    // we want to pass them all in at once
    vitalsArrayMappers.forEach((vital) => {
      const vitals = vitalsArray.filter((v) => vital.type.includes(v.type));
      vitals.sort((a) => (!a.qualifier ? -1 : 1));
      vital.fn(vitals);
    });

    // handle the vitals that don't have qualifiers
    vitalsMappers.forEach((v) => {
      const vital = vitalsArray.find((vital) => v.type.includes(vital.type));
      if (vital?.value) {
        v.fn(vital?.value);
      }
    });
    dispatch(uiActions.setLastSavedVitals());
  }, [data]);

  // Handle BMI update if the weight or height changes
  useEffect(() => {
    if (state.weightVitals?.value && state.heightVitals?.value) {
      dispatch(
        uiActions.setBMIVitals({
          type: VitalType.Bmi,
          value: calculateBMI(state.weightVitals.value, state.heightVitals.value),
        }),
      );
    } else {
      dispatch(uiActions.setBMIVitals({ type: VitalType.Bmi, value: '' }));
    }
  }, [state.weightVitals?.value, state.heightVitals?.value]);

  const isFemale = patient?.gender === Gender.Female;
  function getVitalErrorColor(vitalType: VitalType): string | undefined {
    return validationResult[vitalType]?.first?.color;
  }

  const onAddedHandler = (vitalType: VitalType, value: string) => {
    const errorResult = validateVital({ type: vitalType, value }, patientAge);
    if (errorResult) {
      toast({
        id: `${VitalType.Temp}-${errorResult.enteredValue}`,
        title: errorResult.isWarning ? 'Warning' : 'Error',
        description: errorResult.getErrorMessage(),
        status: errorResult.isWarning ? 'warning' : 'error',
        duration: TOAST_DURATION,
      });
    }
  };
  return (
    <Box>
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing='md'>
        <Box {...cellStyle}>
          <FormControl isInvalid={!!getVitalErrorColor(VitalType.Weight)} variant='floating'>
            <InputGroup>
              <FormLabel>Weight</FormLabel>
              <NumberInput
                onBlur={() => {
                  onBlurHandler(VitalType.Weight);
                }}
                errorBorderColor={getVitalErrorColor(VitalType.Weight)}
                value={state.weightVitals?.value ?? ''}
                width='100%'>
                <NumberInputField
                  placeholder='133'
                  onChange={(e) => {
                    vitalChangeDecorator(handleWeightChange)(e.target.value);
                  }}
                />
              </NumberInput>
              <InputRightElement>
                <Text>lbs</Text>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl variant='floating' isInvalid={!!getVitalErrorColor(VitalType.Height)}>
            <InputGroup>
              <FormLabel>Height</FormLabel>
              <NumberInput
                onBlur={() => {
                  onBlurHandler(VitalType.Height);
                }}
                value={state.heightVitals?.value ?? ''}
                width='100%'
                errorBorderColor={getVitalErrorColor(VitalType.Height)}>
                <NumberInputField
                  placeholder='68'
                  onChange={(e) => {
                    vitalChangeDecorator(handleHeightChange)(e.target.value);
                  }}
                />
              </NumberInput>
              <InputRightElement>
                <Text>in.</Text>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl variant='floating'>
            <TempPicker
              labelElement={<FormLabel>Temp.</FormLabel>}
              validationResult={validationResult[VitalType.Temp]}
              isInvalid={
                !!validationResult[VitalType.Temp]?.getValidationResultWithGivenValue(
                  state?.tempVitals?.[0]?.value,
                )
              }
              onBlur={() => {
                onBlurHandler(VitalType.Temp);
              }}
              errorBorderColor={getVitalErrorColor(VitalType.Temp)}
              value={state.tempVitals || []}
              onChange={vitalChangeDecorator(handleTempChange)}
              onAddedHandler={(value) => {
                onAddedHandler(VitalType.Temp, value);
              }}
            />
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl variant='floating'>
            <HeartRatePicker
              isInvalid={
                !!validationResult[VitalType.HeartRate]?.getValidationResultWithGivenValue(
                  state.heartRateVitals[0]?.value,
                )
              }
              validationResult={validationResult[VitalType.HeartRate]}
              labelElement={<FormLabel>Heart Rate</FormLabel>}
              onBlur={() => {
                onBlurHandler(VitalType.HeartRate);
              }}
              errorBorderColor={getVitalErrorColor(VitalType.HeartRate)}
              value={state.heartRateVitals}
              defaultPlaceholder='99'
              onChange={vitalChangeDecorator(handleHeartRateChange)}
              onAddedHandler={(value) => {
                onAddedHandler(VitalType.HeartRate, value);
              }}
            />
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl variant='floating'>
            <BloodPressurePicker
              isInvalid={
                !!validationResult[VitalType.BloodPress]?.getValidationResultWithGivenValue(
                  state.bloodPressureVitals[0]?.value,
                )
              }
              validationResult={validationResult[VitalType.BloodPress]}
              labelElement={<FormLabel>Blood Pressure</FormLabel>}
              onBlur={() => {
                onBlurHandler(VitalType.BloodPress);
              }}
              errorBorderColor={getVitalErrorColor(VitalType.BloodPress)}
              value={state.bloodPressureVitals}
              defaultPlaceholder='100/60'
              onChange={vitalChangeDecorator(handleBloodPressureChange)}
              onAddedHandler={(value) => {
                onAddedHandler(VitalType.BloodPress, value);
              }}
            />
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl isInvalid={!!getVitalErrorColor(VitalType.WaistCirc)} variant='floating'>
            <InputGroup>
              <FormLabel>Waist Circum.</FormLabel>
              <NumberInput
                onBlur={() => {
                  onBlurHandler(VitalType.WaistCirc);
                }}
                value={state.waistCircVitals?.value ?? ''}
                width='100%'
                errorBorderColor={getVitalErrorColor(VitalType.WaistCirc)}>
                <NumberInputField
                  placeholder='33'
                  onChange={(e) => {
                    vitalChangeDecorator(handleWaistCircChange)(e.target.value);
                  }}
                />
              </NumberInput>
              <InputRightElement>
                <Text>in.</Text>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl variant='floating'>
            <FormLabel>BMI</FormLabel>
            <NumberInput value={state.bmiVitals?.value ?? ''} isDisabled={true}>
              <NumberInputField
                onChange={(e) => {
                  vitalChangeDecorator(handleBMIChange)(e.target.value);
                }}
              />
            </NumberInput>
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl
            isInvalid={!!getVitalErrorColor(VitalType.RespiratoryRate)}
            variant='floating'>
            <FormLabel>Resp. Rate</FormLabel>
            <InputGroup>
              <NumberInput
                onBlur={() => {
                  onBlurHandler(VitalType.RespiratoryRate);
                }}
                value={state.restRateVitals?.value ?? ''}
                width='100%'
                errorBorderColor={getVitalErrorColor(VitalType.RespiratoryRate)}>
                <NumberInputField
                  placeholder='12'
                  onChange={(e) => {
                    vitalChangeDecorator(handleRestRateChange)(e.target.value);
                  }}
                />
              </NumberInput>
              <InputRightElement>
                <Text pr={2}>/min</Text>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>

        <Box {...cellStyle}>
          <FormControl isInvalid={!!getVitalErrorColor(VitalType.Pain)} variant='floating'>
            <FormLabel>Pain Scale</FormLabel>
            <NumberInput
              onBlur={() => {
                onBlurHandler(VitalType.Pain);
              }}
              value={state.painScaleVitals?.value ?? ''}
              min={1}
              max={10}
              errorBorderColor={getVitalErrorColor(VitalType.Pain)}>
              <NumberInputField
                placeholder='1-10'
                onChange={(e) => {
                  vitalChangeDecorator(handlePainScaleChange)(e.target.value);
                }}
              />
            </NumberInput>
          </FormControl>
        </Box>
        {isFemale && (
          <Box {...cellStyle}>
            <FormControl isInvalid={!!getVitalErrorColor(VitalType.Lmp)} variant='floating'>
              <FormLabel>LMP</FormLabel>
              <Input
                onBlur={() => {
                  onBlurHandler(VitalType.Lmp);
                }}
                errorBorderColor={getVitalErrorColor(VitalType.Lmp)}
                value={state.lmpVitals?.value}
                onChange={(e) => {
                  // handle this in the onchange rather than onblur
                  // if user goes from date input to clicking the submit button
                  // the onblur may not get invoked
                  vitalChangeDecorator(handleLMPChange)(e.target.value);
                }}
              />
            </FormControl>
          </Box>
        )}
        <Box {...cellStyle}>
          <FormControl isInvalid={!!getVitalErrorColor(VitalType.O2)} variant='floating'>
            <InputGroup>
              <FormLabel>O2 Sat.</FormLabel>
              <NumberInput
                onBlur={() => {
                  onBlurHandler(VitalType.O2);
                }}
                value={state.o2Sat?.value ?? ''}
                min={1}
                max={100}
                width='100%'
                errorBorderColor={getVitalErrorColor(VitalType.O2)}>
                <NumberInputField
                  onChange={(e) => {
                    vitalChangeDecorator(handleO2VitalsChange)(e.target.value);
                  }}
                />
              </NumberInput>
              <InputRightElement>
                <Text pr={2}>%</Text>
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </Box>
      </SimpleGrid>
      <SaveAndWorklistButtons
        onClick={saveHandler}
        isLoading={isSavingVitals || isLoadindgConstellation}>
        Save Vitals
      </SaveAndWorklistButtons>
    </Box>
  );
};
