import _, { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VStack, Text, Box, WaitUntilLoaded, Page } from '../../../components';
import { IMMUNIZATION_DIAGNOSIS } from '../../../constants';
import { usePatient } from '../../../hooks';
import {
  appointmentsActions,
  type AppointmentsState,
  type EditedOrder,
  selectors,
  useAppDispatch,
} from '../../../state-management';
import {
  getDefaultDiagnosis,
  hasAutoPickedDiagnosis,
  isReadOnlyEditedOrder,
  isRecommendationInjectionOrImmunization,
} from '../../../utils';
import { ReviewOrderCard } from './ReviewOrderCard';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { type Problem } from '../../../__generated__/graphql';
import { type GetPatientRecommendation } from '../../../types';
import { useAddEditedOrdersToTreatmentPlan } from './useAddEditedOrdersToTreatmentPlan';
import { useGetEncounterOrders } from './useGetEncounterOrders';

function EditedOrders() {
  const dispatch = useAppDispatch();
  const { patient, loading: isLoading } = usePatient();
  const { patientID } = patient;
  const {
    regularOrders,
    immunizationOrders,
    loading: isLoadingEncounterOrders,
  } = useGetEncounterOrders();
  const recommendations = patient?.recommendations;
  // We don't want to display CDSS recommendations that have already been added to treatment plan.
  // If a recommendation's orderableItemId is already added to the treatment plan i.e. it is in the regularOrders or immunizationOrders, then filter it out
  const filteredRecommendations = recommendations?.filter((recommendation) => {
    if (regularOrders?.some((order) => order?.labItemId === recommendation?.orderableItemId)) {
      return false;
    }

    if (
      immunizationOrders?.some(
        (order) => order?.immunizationItemId === recommendation?.orderableItemId,
      )
    ) {
      return false;
    }

    // Ensure immunizationInjection orders with multiple orderableItemOptions that have already been added to Treatment Plan are not displayed
    if (
      immunizationOrders?.some((order) => {
        return recommendation?.orderableItemOptions?.some((option) => {
          return order?.immunizationItemId === option?.orderableItemId;
        });
      })
    ) {
      return false;
    }

    // Ensure regularOrders (labs, imaging, procedures) with multiple orderableItemOptions that have already been added to Treatment Plan are not displayed
    if (
      regularOrders?.some((order) => {
        return recommendation?.orderableItemOptions?.some((option) => {
          return order?.labItemId === option?.orderableItemId;
        });
      })
    ) {
      return false;
    }

    return true;
  });

  const editedOrdersState = useSelector(selectors.getEditedOrders);
  const editedOrders = Object.values(editedOrdersState);

  // Setup editable orders in store
  React.useEffect(() => {
    // Prep edited orders
    const newEditedOrders = filteredRecommendations?.reduce(
      (acc: AppointmentsState['editedOrders'], recommendation: GetPatientRecommendation) => {
        const isReadOnly =
          typeof recommendation?.isOrderable === 'boolean' && !recommendation?.isOrderable;

        const editedOrder: EditedOrder = {
          status: 'YES_ADDING_DIAGNOSIS',
          alert: {
            alertId: recommendation?.orderableItemId || 0,
            shortDescription: recommendation?.orderableItemName || '',
            longDescription: recommendation?.description || '',
            ...recommendation,
          },
          orderType: isReadOnly ? 'READONLY' : 'ACCEPTED',
        };

        // Pre-select 'Z23' diagnosis for immunization or injection orders
        // (solely for visual effects. This isn't submitted for immunizations)
        if (isRecommendationInjectionOrImmunization(editedOrder)) {
          editedOrder.diagnosis = IMMUNIZATION_DIAGNOSIS;
        }

        // Pre-select any recommendation with a default diagnosis
        const defaultDiagnosis = getDefaultDiagnosis(editedOrder);
        if (defaultDiagnosis != null) {
          editedOrder.diagnosis = defaultDiagnosis;
        }

        acc[recommendation?.orderableItemId || -1] = editedOrder;

        return acc;
      },
      {},
    );

    // Put edited orders in store
    if (newEditedOrders != null) {
      dispatch(appointmentsActions.createEditedOrders(newEditedOrders));
    }
  }, [patientID, dispatch, filteredRecommendations?.length]);

  const sortedEditOrders = useMemo(() => {
    return editedOrders.sort((o1, o2) => {
      if (_.isNil(o1.alert?.displayOrder)) {
        return -1;
      }
      if (_.isNil(o2.alert?.displayOrder)) {
        return 1;
      }
      return o1.alert?.displayOrder - o2.alert.displayOrder;
    });
  }, [editedOrdersState]);
  return (
    <WaitUntilLoaded loading={isLoading || isLoadingEncounterOrders}>
      {editedOrders?.length === 0 ? (
        <Box w='full'>
          <Text textAlign='center'>No items.</Text>
        </Box>
      ) : (
        <VStack spacing='lg' w='full'>
          {sortedEditOrders.map((editedOrder) => {
            const alertId = editedOrder.alert.alertId;
            return <ReviewOrderCard alertId={alertId} key={alertId} />;
          })}
        </VStack>
      )}
    </WaitUntilLoaded>
  );
}

function isAbleToOrder({
  editedOrders,
  problems = [],
}: {
  editedOrders: EditedOrder[];
  problems?: Problem[] | null;
}) {
  return !editedOrders.some((editedOrder) => {
    if (hasAutoPickedDiagnosis(editedOrder)) {
      // This allows diagnosis to be auto-picked even if it isn't in Saved state yet.
      return false;
    }

    // If the user has no problems/diagnosis, still allow them to order even if the recommendation state is "YES_ADDING_DIAGNOSIS"
    if (editedOrder.status === 'YES_ADDING_DIAGNOSIS' && isEmpty(problems)) {
      return false;
    }

    // If a recommendation is NOT orderable, that means it is read-only in UI.
    // Allow allow the entire order still go, as we won't be selecting any diagnosis e.t.c for it.
    if (isReadOnlyEditedOrder(editedOrder)) {
      return false;
    }
    return (
      editedOrder.status !== 'YES_SAVED_DIAGNOSIS' &&
      editedOrder.status !== 'NO_SAVED_DECLINE_REASON'
    );
  });
}

export function CDSSRecommendations({
  onAddToTreatmentPlanSuccess,
}: {
  onAddToTreatmentPlanSuccess: () => void;
}) {
  const editedOrdersState = useSelector(selectors.getEditedOrders);
  const editedOrders = Object.values(editedOrdersState);
  const { patient, loading: loadingPatient } = usePatient();
  const problems = patient?.problems;
  const canAddToTreatmentPlan = isAbleToOrder({
    editedOrders,
    problems,
  });

  const { handleAddEditedOrdersToTreatmentPlan, isSubmitting } =
    useAddEditedOrdersToTreatmentPlan();

  function handleAddToTreatmentPlan() {
    if (canAddToTreatmentPlan) {
      handleAddEditedOrdersToTreatmentPlan({ onAddToTreatmentPlanSuccess });
    }
  }

  return (
    <Page>
      <VStack spacing='lg' alignItems='flex-start'>
        <Box w='full' overflow='auto'>
          <VStack spacing='md' w='full' pt='sm' pb='lg' px='sm' alignItems='stretch'>
            <EditedOrders />

            {isEmpty(editedOrders) ? null : (
              <SaveAndWorklistButtons
                isDisabled={!canAddToTreatmentPlan}
                isLoading={isSubmitting}
                onClick={handleAddToTreatmentPlan}>
                Add To Treatment Plan
              </SaveAndWorklistButtons>
            )}

            {!canAddToTreatmentPlan && !loadingPatient && (
              <Text color='red' variant='body2-b' textAlign='center'>
                Please address all items to proceed
              </Text>
            )}
            <Box height='50px' />
          </VStack>
        </Box>
      </VStack>
    </Page>
  );
}
