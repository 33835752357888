import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  Select,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { DatePicker } from '../../form';
import { DoseSelect, ProviderAndStaffComboBox } from '../../../features';
import { useAuth, useEncounter, useModal, usePatient } from '../../../hooks';
import { LotSearchButton } from './LotSearchButton';
import { RouteSelect } from './RouteSelect';
import { useAdministerVaccineForm } from './useAdministerVaccineForm';
import { InjectionSiteSelect } from './InjectionSiteSelect';
import { useEffect, useState } from 'react';
import { VFCSelect } from './VFCSelect';
import {
  type SubmitImmunizationInjectionInput,
  type ImmunizationInjectionItem,
} from '../../../__generated__/graphql';
import { formatDate, isWorklistVaccineFormDirty } from '../../../utils';

interface AdministerVaccineFormModalProps {
  pastVaccineDetail: ImmunizationInjectionItem;
  setValidated: (isValidated: boolean) => void;
  type: 'Vaccination' | 'Therapeutic Injection';
  isDisabled?: boolean;
}

function AdministerVaccineForm({
  pastVaccineDetail,
  setValidated,
  isDisabled = false,
}: AdministerVaccineFormModalProps) {
  const { user } = useAuth();
  const fullName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const defaultGivenBySelection = pastVaccineDetail?.administeredByUserId
    ? {
        label: pastVaccineDetail?.administeredByUserName ?? '',
        value: pastVaccineDetail?.administeredByUserId?.toString() ?? '',
      }
    : { label: fullName, value: user.ecwId ? user.ecwId : '' };
  const { formValues, handleUpdateForm, handleResetVaccineForm, activePendingVaccine } =
    useAdministerVaccineForm();
  const { patientId } = usePatient();
  const { activeEncounterID } = useEncounter();
  const immunizationItemId = activePendingVaccine?.id;

  useEffect(() => {
    const isDirty = isWorklistVaccineFormDirty(pastVaccineDetail);

    // Init form values
    if (isDirty) {
      // init with Pre-existing values
      handleUpdateForm({
        ...pastVaccineDetail,
        patientImmunizationInjectionAssessments: (
          pastVaccineDetail.patientImmunizationInjectionAssessments ?? []
        ).map((assessment) => ({
          immunizationID: assessment.immunizationID ?? -1,
          assessmentValue: assessment.assessmentValue ?? '',
          assessmentName: assessment.assessmentName ?? '',
          assessmentId: assessment.assessmentId ?? -1,
        })),
        statusId: pastVaccineDetail.statusId ?? undefined,
      });
    } else {
      // init with Default values
      handleUpdateForm({
        vaccinationGivenInThePast: false,
        dateTimeAdministered: new Date().toISOString(),
        statusId: 1, // "Administered",
        immunizationId: activePendingVaccine?.immunization?.immunizationId,
        immunizationName: activePendingVaccine?.immunization?.immunizationName,
        patientImmunizationInjectionType:
          activePendingVaccine?.immunization?.patientImmunizationInjectionType,
        billable: true,
      });
    }

    return () => {
      // clear form values when unmounting
      handleResetVaccineForm();
    };
  }, []);

  // Set "given by"
  useEffect(() => {
    handleUpdateForm({
      administeredByUserName: defaultGivenBySelection.label,
      administeredByUserId: Number(defaultGivenBySelection.value),
    });
  }, [defaultGivenBySelection.label, defaultGivenBySelection.value]);

  // Set patientID
  useEffect(() => {
    if (patientId) {
      handleUpdateForm({
        sourcePatientId: patientId,
      });
    }
  }, [patientId]);

  // Set encounter id
  useEffect(() => {
    if (activeEncounterID) {
      handleUpdateForm({
        sourceEncounterId: activeEncounterID,
      });
    }
  }, [activeEncounterID]);

  // Set immunizationItemId
  useEffect(() => {
    if (immunizationItemId) {
      handleUpdateForm({
        immunizationItemId,
      });
    }
  }, [immunizationItemId]);

  // Move this useEffect outside of the conditional rendering
  useEffect(() => {
    if (formValues?.statusId === 1) {
      setValidated(!!(formValues?.lotId && formValues?.injectionLocation));
    } else {
      setValidated(true);
    }
  }, [formValues?.lotId, formValues?.injectionLocation, formValues?.statusId, setValidated]);

  // Watch given in past and update status when true
  useEffect(() => {
    if (formValues?.vaccinationGivenInThePast) {
      handleUpdateForm({
        statusId: 1, // Set to administered
      });
    }
  }, [formValues?.vaccinationGivenInThePast]);

  // For can either have values being edited currently, or past details returned from server shown in fields in a disabled state
  const isPastVaccineDetail = !!pastVaccineDetail && isDisabled;

  return (
    <Box h='70vh'>
      <Flex justifyContent={'space-between'} mb='lg'>
        <Text variant='h6-b'>
          {isDisabled ? pastVaccineDetail.immunizationName : activePendingVaccine?.text}
        </Text>
        <HStack spacing='sm' position='relative'>
          <Text>Given in Past</Text>
          <Switch
            isDisabled={!!isPastVaccineDetail}
            colorScheme='brand'
            isChecked={
              isPastVaccineDetail
                ? !!pastVaccineDetail.vaccinationGivenInThePast
                : !!formValues?.vaccinationGivenInThePast
            }
            onChange={(e) => {
              handleUpdateForm({
                vaccinationGivenInThePast: e.target.checked,
                billable: !e.target.checked,
              });
            }}
          />
        </HStack>
      </Flex>

      <VStack alignItems={'stretch'} spacing={'lg'}>
        <RenderGivenInPastForm
          {...{
            handleUpdateForm,
            pastVaccineDetail,
            isPastVaccineDetail,
            isDisabled,
            defaultGivenBySelection,
            formValues,
            visible: !!formValues?.vaccinationGivenInThePast,
          }}
        />

        <RenderDefaultForm
          {...{
            handleUpdateForm,
            pastVaccineDetail,
            isPastVaccineDetail,
            isDisabled,
            defaultGivenBySelection,
            formValues,
            visible: !formValues?.vaccinationGivenInThePast,
          }}
        />
      </VStack>
    </Box>
  );
}

interface DefaultGivenBySelection {
  label: string;
  value: string;
}

interface renderFormProps {
  handleUpdateForm: (updateValues: Partial<SubmitImmunizationInjectionInput> | undefined) => void;
  pastVaccineDetail: ImmunizationInjectionItem;
  isPastVaccineDetail: boolean;
  isDisabled: boolean;
  defaultGivenBySelection: DefaultGivenBySelection;
  formValues?: Partial<SubmitImmunizationInjectionInput>;
  visible: boolean;
}

function RenderDefaultForm({
  handleUpdateForm,
  pastVaccineDetail,
  isPastVaccineDetail,
  isDisabled,
  defaultGivenBySelection,
  formValues,
  visible,
}: renderFormProps) {
  const [doseUnit, setDoseUnit] = useState('');

  if (!visible) return null;

  return (
    <VStack alignItems={'stretch'} spacing={'lg'}>
      <SimpleGrid columns={3} spacing={'md'}>
        <FormControl>
          <FormLabel>
            <HStack>
              <Text>Lot #</Text>
              {formValues?.statusId === 1 && <Text color='red'>*</Text>}
            </HStack>
          </FormLabel>
          {isPastVaccineDetail ? (
            <Input isDisabled value={pastVaccineDetail?.lotNumber ?? '-'} />
          ) : (
            <Flex justifyContent='space-between' alignItems='center'>
              <Text color={formValues?.lotNumber ? 'black' : 'grey'}>
                {formValues?.lotNumber ?? 'None selected'}
              </Text>
              <LotSearchButton />
            </Flex>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Dose</FormLabel>
          {isPastVaccineDetail ? (
            <Input isDisabled value={pastVaccineDetail.dose ?? '-'} />
          ) : (
            <Input
              placeholder='Dose'
              onBlur={() => {
                if (formValues?.dose) {
                  const dose = formValues.dose.match(/\d+(\.\d+)?/)?.[0] ?? '';

                  handleUpdateForm({
                    dose: `${dose} ${doseUnit}`,
                  });
                }
              }}
              value={formValues?.dose ?? ''}
              onChange={(e) => {
                handleUpdateForm({
                  dose: e.target.value,
                });
              }}
            />
          )}
        </FormControl>

        {!isPastVaccineDetail && (
          <FormControl>
            <FormLabel>Unit</FormLabel>
            <DoseSelect
              isDisabled={isDisabled}
              onChange={(unit) => {
                setDoseUnit(unit);
                if (!formValues?.dose) return;
                const dose = formValues.dose.match(/\d+(\.\d+)?/)?.[0] ?? '';

                handleUpdateForm({
                  dose: `${dose} ${unit}`,
                });
              }}
            />
          </FormControl>
        )}

        <FormControl>
          <FormLabel>Route</FormLabel>
          {isPastVaccineDetail ? (
            <Input disabled value={pastVaccineDetail?.route ?? '-'} />
          ) : (
            <RouteSelect />
          )}
        </FormControl>

        <FormControl>
          <FormLabel>Dose #</FormLabel>
          <Input
            isDisabled={isDisabled}
            placeholder='Dose #'
            value={formValues?.doseNumber ?? ''}
            onChange={(e) => {
              handleUpdateForm({
                doseNumber: e.target.value,
              });
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <HStack>
              <Text> Location</Text>
              {formValues?.statusId === 1 && <Text color='red'>*</Text>}
            </HStack>
          </FormLabel>
          {isPastVaccineDetail ? (
            <Input disabled value={pastVaccineDetail?.injectionLocation ?? '-'} />
          ) : (
            <InjectionSiteSelect />
          )}
        </FormControl>
      </SimpleGrid>

      <Divider />

      <SimpleGrid columns={3} spacing={'md'}>
        <FormControl>
          <FormLabel>Given Date/Time</FormLabel>
          {isDisabled ? (
            <Input
              disabled
              value={
                pastVaccineDetail?.dateTimeAdministered
                  ? formatDate(pastVaccineDetail.dateTimeAdministered)
                  : '-'
              }
            />
          ) : (
            <DatePicker
              isDisabled={isDisabled}
              placeholder='Given Date/Time'
              value={
                formValues?.dateTimeAdministered ? new Date(formValues.dateTimeAdministered) : null
              }
              onChange={(date) => {
                handleUpdateForm({
                  dateTimeAdministered: date?.toISOString() ?? '',
                });
              }}
            />
          )}
        </FormControl>

        <FormControl>
          <FormLabel>Given By</FormLabel>
          {isDisabled ? (
            <Input isDisabled value={pastVaccineDetail?.administeredByUserName ?? '-'} />
          ) : (
            <ProviderAndStaffComboBox
              placeholder='Given By'
              selected={defaultGivenBySelection}
              selectionCallback={(person) => {
                handleUpdateForm({
                  administeredByUserName: person?.label ?? '',
                  administeredByUserId: person?.value ? Number(person?.value) : 0,
                });
              }}
            />
          )}
        </FormControl>

        <FormControl>
          {/* Per Brannon:
                The statusId is the id of the related ImmunizationStatus.  
                For example statusId: 
                1 - Administered, statusId: 
                2 - Partially Administered, statusId: 
                3 - Not Administered, statusId: 
                4 - Refused (if the Patient Refused the offered immunization/injection).
             */}
          <FormLabel>Status</FormLabel>
          {isDisabled ? (
            <Input isDisabled value={pastVaccineDetail?.statusDescription ?? '-'} />
          ) : (
            <Select
              placeholder='Status'
              value={formValues?.statusId}
              onChange={(e) => {
                handleUpdateForm({
                  statusId: Number(e.target.value),
                  statusDescription: e.target.selectedOptions[0].text,
                });
              }}>
              <option value={1}>Administered</option>
              <option value={2}>Partially Administered</option>
              <option value={3}>Not Administered</option>
              <option value={4}>Refused</option>
            </Select>
          )}
        </FormControl>
      </SimpleGrid>

      <Divider />

      <SimpleGrid columns={3} spacing={'md'}>
        <FormControl>
          <FormLabel>VFC</FormLabel>
          {isDisabled ? (
            <Input isDisabled value={pastVaccineDetail?.vfcCode ?? '-'} />
          ) : (
            <VFCSelect />
          )}
        </FormControl>

        <FormControl>
          <FormLabel>VIS Given Date</FormLabel>
          {isDisabled ? (
            <Input
              disabled
              value={
                pastVaccineDetail?.dateVISGiven ? formatDate(pastVaccineDetail.dateVISGiven) : '-'
              }
            />
          ) : (
            <DatePicker
              placeholder='VIS Given Date'
              value={formValues?.dateVISGiven ? new Date(formValues.dateVISGiven) : null}
              onChange={(date) => {
                handleUpdateForm({
                  dateVISGiven: date?.toISOString() ?? '',
                });
              }}
            />
          )}
        </FormControl>

        <FormControl>
          <FormLabel>Date On VIS</FormLabel>
          {isDisabled ? (
            <Input
              disabled
              value={pastVaccineDetail?.dateOnVIS ? formatDate(pastVaccineDetail.dateOnVIS) : '-'}
            />
          ) : (
            <DatePicker
              placeholder='Date On VIS'
              value={formValues?.dateOnVIS ? new Date(formValues.dateOnVIS) : null}
              onChange={(date) => {
                handleUpdateForm({
                  dateOnVIS: date?.toISOString() ?? '',
                });
              }}
            />
          )}
        </FormControl>
      </SimpleGrid>

      <Divider />

      <Flex>
        <FormControl>
          <Textarea
            placeholder='Comments'
            onChange={(e) => {
              handleUpdateForm({ notes: e.target.value });
            }}
          />
        </FormControl>
      </Flex>
    </VStack>
  );
}

function RenderGivenInPastForm({
  handleUpdateForm,
  pastVaccineDetail,
  isDisabled,
  formValues,
  visible,
}: renderFormProps) {
  if (!visible) return null;

  return (
    <VStack>
      <SimpleGrid columns={2} spacing={'md'} width='100%'>
        <FormControl>
          <FormLabel>Dose #</FormLabel>
          <Input
            isDisabled={isDisabled}
            placeholder='Dose #'
            value={formValues?.doseNumber ?? ''}
            onChange={(e) => {
              handleUpdateForm({
                doseNumber: e.target.value,
              });
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Given Date/Time</FormLabel>
          {isDisabled ? (
            <Input
              disabled
              value={
                pastVaccineDetail?.dateTimeAdministered
                  ? formatDate(pastVaccineDetail.dateTimeAdministered)
                  : '-'
              }
            />
          ) : (
            <DatePicker
              isDisabled={isDisabled}
              placeholder='Given Date/Time'
              value={
                formValues?.dateTimeAdministered ? new Date(formValues.dateTimeAdministered) : null
              }
              onChange={(date) => {
                handleUpdateForm({
                  dateTimeAdministered: date?.toISOString() ?? '',
                });
              }}
            />
          )}
        </FormControl>
      </SimpleGrid>

      <Divider marginTop='sm' marginBottom='sm' />

      <FormControl>
        <Textarea
          placeholder='Comments'
          onChange={(e) => {
            handleUpdateForm({ notes: e.target.value });
          }}
        />
      </FormControl>
    </VStack>
  );
}

export function AdministerVaccineFormModal(props: AdministerVaccineFormModalProps) {
  const { showModal } = useModal();
  const { handleSubmitForm, isSubmittingForm, formValues } = useAdministerVaccineForm();
  const [_validated, setValidated] = useState<boolean>(true);
  const validated = !!formValues?.vaccinationGivenInThePast || _validated;

  return (
    <>
      <ModalBody>
        <AdministerVaccineForm {...props} setValidated={setValidated} />
      </ModalBody>
      <ModalFooter>
        <Button
          variant='ghost'
          onClick={() => {
            showModal({
              modalType: 'PendingVaccinesModal',
              modalProps: { type: props.type },
              chakraModalProps: { size: '6xl' },
            });
          }}>
          Back
        </Button>
        {props.isDisabled ? null : (
          <Tooltip
            label={!validated && 'Lot # and Location are required when status is "Administered"'}
            placement='top-end'
            hasArrow>
            <Button
              isDisabled={!validated}
              onClick={() => {
                handleSubmitForm(props.type);
              }}
              isLoading={isSubmittingForm}>
              Done
            </Button>
          </Tooltip>
        )}
      </ModalFooter>
    </>
  );
}
