import { useSelector } from 'react-redux';
import { useActiveEncounter, useModal, useNavigation, usePatient } from '../../../hooks';
import { Circle, IconButton } from '../../general';
import { Text } from '../../typography';
import {
  getOpenDrawer,
  modalActions,
  selectors,
  uiActions,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { FiArrowLeft } from 'react-icons/fi';
import {
  Button,
  Drawer,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { PatientRoomNumber } from '../PatientRoomNumber/PatientRoomNumber';
// TODO: We should refactor UserInfo. It is tightly coupled to other areas using it, and ideally
// should not have context of those components. e.g. useWorklistModal and drawerLocation
// We should make it a reusable component which can be extended by its clients.
import { useEncounters } from '../../../screens/PatientSearchScreen/components/EncounterCardList/useEncounters'; // TODO: Make useEncounters a reusable hook, outside /screens
import { Labs } from './LabsPanel';
import { useWorklistModal } from '../../modals/WorklistModal/useWorklistModal';
import { PatientPharmacy } from './PatientPharmacy';
import { EditIcon } from '../../svgs/Edit';
import { Problems } from './Problems';

interface Props {
  size?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
}

export function Avatar({ size = 14, maxWidth, minWidth }: Props) {
  const patientInitials = useSelector(selectors.getPatientInitials);

  return (
    <Circle
      aspectRatio={1}
      minHeight={minWidth}
      maxHeight={maxWidth}
      maxWidth={maxWidth}
      minWidth={minWidth}
      bg='primary'
      size={size}>
      <Text variant='h6-b' fontSize='sm' color='white'>
        {patientInitials}
      </Text>
    </Circle>
  );
}

export function UserInfo({
  location = 'workflow',
}: {
  location?: 'workflow' | 'labSubmission' | 'aoe' | 'labsTab';
}) {
  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector(getOpenDrawer);
  const navigation = useNavigation();
  const { patient } = usePatient();
  const { showModal } = useModal();

  const navigationEnabled = location === 'workflow';
  function handleUserInfoPress() {
    if (navigationEnabled) {
      navigation.toPatientViewPage();
    }
  }

  function handleNavBack() {
    navigation.back();
  }

  const id = useSelector(selectors.getPatientId);
  const name = useSelector(selectors.getPatientFullName);
  const ageGenderBirthday = useSelector(selectors.getAgeGenderBirthday);
  const visitReason = useSelector(selectors.getVisitReason);
  const visitDate = useSelector(selectors.getVisitDate);
  const drawerLocation = useSelector(selectors.getDrawerLocation);
  const insurance = patient?.insurance;
  const { activeEncounter, activeEncounterId } = useActiveEncounter();
  const { updateEncounter } = useEncounters();
  const { toLabCollectionFormModal, toAOEQuestionsAndLabForms } = useWorklistModal();

  const subItems = [
    `Appt ${visitDate && new Date(visitDate).toLocaleString()}`,
    `${id} ${ageGenderBirthday}`,
    visitReason,
  ];

  return (
    <HStack>
      {/* Patient Problem List / Labs right side panel */}
      <Drawer
        isOpen={!!openDrawer}
        placement='right'
        size={openDrawer === 'labs' ? 'full' : 'md'}
        onClose={() => {
          if (['aoe', 'labSubmission'].includes(drawerLocation)) {
            drawerLocation === 'aoe' ? toLabCollectionFormModal() : toAOEQuestionsAndLabForms();
          }
          dispatch(uiActions.onDrawerChange());
        }}>
        <DrawerOverlay />

        {openDrawer === 'problems' ? <Problems /> : <Labs />}
      </Drawer>

      {navigationEnabled && (
        <IconButton
          aria-label='back'
          size='sm'
          variant='ghost'
          onClick={handleNavBack}
          icon={<Icon boxSize={5} as={FiArrowLeft} />}
        />
      )}

      <HStack whiteSpace='nowrap' w='fit-content' cursor={navigationEnabled ? 'pointer' : ''}>
        <Flex flexDirection='column' alignItems='center' onClick={handleUserInfoPress}>
          <Avatar minWidth={10} maxWidth={12} size='5.5vw' />
          {activeEncounter && (
            <PatientRoomNumber
              mt='4px'
              ml='-4px'
              mb='7px'
              bgColor='white'
              w='65px'
              borderRadius='5px 5px 0 0'
              placeholder='N/A'
              roomNumber={activeEncounter.roomNumber}
              updateRoomNumber={async (encounterId: number, roomNumber: string) => {
                await updateEncounter(encounterId, { roomNumber });
              }}
              encounterID={activeEncounter.encounterID}
            />
          )}
          <Tooltip
            hasArrow
            isDisabled={!activeEncounter?.clinicalStatusAfterCheckIn}
            label={activeEncounter?.clinicalStatusAfterCheckIn}>
            <InputGroup
              size='xs'
              mt='-8px'
              ml='-4px'
              bgColor='white'
              w='65px'
              onClick={(e) => {
                activeEncounter?.clinicalStatusAfterCheckIn &&
                  showModal({
                    modalType: 'ClinicalStatusSelectModal',
                    chakraModalProps: {
                      size: 'lg',
                    },
                    modalProps: {
                      clinicalStatusAfterCheckIn: activeEncounter?.clinicalStatusAfterCheckIn,
                      updateClinicalStatus: async (clinicalStatusAfterCheckIn: string) => {
                        await updateEncounter(activeEncounter?.encounterID ?? 0, {
                          clinicalStatusAfterCheckIn,
                        });
                      },
                      navigateModalType:
                        location === 'workflow'
                          ? null
                          : location === 'aoe'
                          ? 'LabCollectionFormModal'
                          : 'AOEQuestionsAndLabFormsModal',
                    },
                  });
                e.stopPropagation();
              }}>
              <Input
                fontWeight={700}
                color='secondary'
                value={activeEncounter?.clinicalStatusAfterCheckIn || ''}
                borderRadius='0 0 5px 5px'
                isReadOnly
              />
              {!!activeEncounter?.clinicalStatusAfterCheckIn?.length && (
                <InputRightElement>
                  <EditIcon boxSize={5} color='secondary' />
                </InputRightElement>
              )}
            </InputGroup>
          </Tooltip>
        </Flex>
        <Flex>
          <Flex flexDirection='column' alignItems='flex-start' onClick={handleUserInfoPress}>
            <HStack>
              <Text fontSize='xl' fontWeight='bold' lineHeight='short'>
                {name}
              </Text>
              <Text
                fontSize='lg'
                fontWeight='bold'
                lineHeight='short'
                textColor='white'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}>
                {activeEncounterId}
              </Text>
            </HStack>

            <Text fontWeight='bold' lineHeight='short'>
              {subItems[0]}
            </Text>
            {subItems.slice(1, 3).map((item) => {
              return (
                // hex code for color specifically requested...
                <Text key={item} color='#5E6C9B' fontSize='sm' lineHeight='short'>
                  {item}
                </Text>
              );
            })}
            <Text color='#5E6C9B' fontSize='sm' lineHeight='short' noOfLines={1}>
              {insurance || 'Insurance: N/A'}
            </Text>
            <PatientPharmacy />
          </Flex>

          <Flex alignItems='center'>
            <VStack gap={2} paddingLeft='sm' paddingRight='sm'>
              <Problems />
              <Button
                width='100%'
                variant={openDrawer === 'labs' ? 'solid' : 'outline'}
                height='24px'
                onClick={() => {
                  if (['aoe', 'labSubmission'].includes(location)) {
                    dispatch(modalActions.hideModal());
                  }
                  dispatch(uiActions.onDrawerLocationChange(location));
                  dispatch(uiActions.onDrawerChange('labs'));
                }}>
                <Text fontSize='xs'>Labs</Text>
              </Button>
            </VStack>
          </Flex>
        </Flex>
      </HStack>
    </HStack>
  );
}
