import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  HStack,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Text,
  VStack,
  Box,
  Center,
  Input,
  WaitUntilLoaded,
} from '../../../components';

import {
  selectors,
  uiActions,
  uiSlice,
  useAppDispatch,
  useAppSelector,
} from '../../../state-management';
import { type ChiefComplaint, type ChiefComplaintsInput } from '../../../types';
import {
  useAppUI,
  useCustomToast,
  useDebounce,
  useEncounter,
  useMAWorkflow,
  usePatient,
  useConstellationRecommendation,
} from '../../../hooks';
import { useChiefComplaints } from './useChiefComplaints';
import { AsyncSelectField } from '../../../features';
import { ChiefComplaintEmptyStateIllustration } from './ChiefComplaintEmptyStateIllustration';
import { isEmpty } from 'lodash';
import MAWorkFlowHeader from '../MAWorkFlowHeader';
import { SaveAndWorklistButtons } from '../../components/SaveAndChecklistButtons';
import { MAWorkFlowContentContainer } from '../MAWorkFlowContentContainer';
import {
  type MedicalHistory,
  useGetChiefComplaintsQuery,
  useGetEncounterChiefComplaintsQuery,
  useGetMedicalHistoryQuery,
  useUpdateEncounterChiefComplaintsMutation,
} from '../../../__generated__/graphql';

function isMedicalHistory(item: MedicalHistory | ChiefComplaint): item is MedicalHistory {
  return typeof item === 'string';
}

function getFollowUpItems(medicalHistory?: MedicalHistory[] | null) {
  return medicalHistory?.map((item) => `Follow up: ${item.historyText}`);
}

function AddedChiefComplaints() {
  const dispatch = useAppDispatch();
  const { complaintNames } = useChiefComplaints();
  const [editIdx, setEditIdx] = useState<number | undefined>();
  const [editValue, setEditValue] = useState<string>('');

  function handleRemoveChiefComplaint(complaintName: string) {
    dispatch(uiSlice.actions.removeChiefComplaint(complaintName));
  }

  function handleEditComplaint(originalComplaintName: string, updatedComplaintName: string) {
    const updatedComplaintNames = complaintNames.map((complaintName) => {
      if (complaintName === originalComplaintName) return updatedComplaintName;
      return complaintName;
    });
    dispatch(uiSlice.actions.setChiefComplaints(updatedComplaintNames));
  }
  return isEmpty(complaintNames) ? (
    <Center mt='8' p='md'>
      <VStack spacing='md'>
        <ChiefComplaintEmptyStateIllustration />
        <Text color='secondary'>To get started, search and add a chief complaint.</Text>
      </VStack>
    </Center>
  ) : (
    <VStack spacing='sm' w='full' p='md' pt={0}>
      {complaintNames.map((complaint, idx) => {
        return (
          <Card
            key={complaint}
            p='md'
            w='full'
            onClick={() => {
              // if current index is already in edit mode, do not reset it's value
              if (editIdx === idx) return;

              setEditIdx(idx);
              setEditValue(complaint);
            }}>
            <HStack justifyContent='space-between'>
              {editIdx === idx ? (
                <Input
                  flex={1}
                  value={editValue}
                  onChange={(e) => {
                    setEditValue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleEditComplaint(complaint, editValue);
                      setEditIdx(undefined);
                      e.preventDefault();
                    }
                  }}
                />
              ) : (
                <Text>{complaint}</Text>
              )}
              {editIdx === idx ? (
                <HStack>
                  <Button
                    variant='ghost'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditComplaint(complaint, editValue);
                      setEditIdx(undefined);
                    }}>
                    Done
                  </Button>
                  <Button
                    variant='ghost'
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditIdx(undefined);
                    }}>
                    Cancel
                  </Button>
                </HStack>
              ) : (
                <Box>
                  <Button
                    variant='ghost'
                    onClick={(e) => {
                      setEditIdx(idx);
                      setEditValue(complaint);
                      e.stopPropagation();
                    }}>
                    Edit
                  </Button>
                  <Button
                    isDisabled={editIdx !== undefined}
                    variant='ghost'
                    onClick={(e) => {
                      handleRemoveChiefComplaint(complaint);
                      e.stopPropagation();
                    }}>
                    Remove
                  </Button>
                </Box>
              )}
            </HStack>
          </Card>
        );
      })}
      <Box height='50px' />
    </VStack>
  );
}

type ComplaintType = 'complaint' | 'follow-up';

export function ChiefComplaintsPanel({ nextStep }: { nextStep: () => void }) {
  const { patientId } = usePatient();
  const { activeEncounterID } = useEncounter();
  const { getHasLoadedInitialData } = useMAWorkflow();
  const hasLoadedInitialData = getHasLoadedInitialData('chief-complaint');
  const toast = useCustomToast();
  const { saveHandler, isLoading: isLoadindgConstellation } =
    useConstellationRecommendation(saveChiefComplaints);
  const { complaintNames } = useChiefComplaints();
  const lastUpdate = useAppSelector((state) => state.refetchData.encounterTemplateLastApplyTime);
  /**
   * Note: This magic had to be done b/c at the time saveHandler is initialized
   * it capture the prior value of complaintNames. By doing this it captures the
   * reference to complaintNamesRef, but complaintNamesRef[0] is always pointing
   * to the latest version of complaintNames
   */
  const [complaintNamesRef] = useState<string[][]>([]);
  complaintNamesRef[0] = complaintNames;
  const {
    data: existingComplaintsCacheData,
    loading: loadingExistingComplains,
    refetch: refetchChiefComplaints,
  } = useGetEncounterChiefComplaintsQuery({
    variables: { patientId, encounterId: activeEncounterID },
    skip: hasLoadedInitialData,
  });

  const existingComplaintsCache = existingComplaintsCacheData?.getEncounterChiefComplaints;

  useEffect(() => {
    void refetchChiefComplaints();
  }, [lastUpdate]);
  useEffect(() => {
    if (existingComplaintsCache && !hasLoadedInitialData) {
      dispatch(uiActions.setHasLoadedInitialData('chief-complaint'));
      dispatch(uiActions.setChiefComplaints(existingComplaintsCache));
    }
  }, [existingComplaintsCache, existingComplaintsCache]);

  const dispatch = useAppDispatch();
  const activePatientID = useAppSelector(selectors.getActivePatientID);

  // Input field search
  const [complaintType, setComplaintType] = useState<ComplaintType>('complaint');
  const searchText = useAppSelector((state) => state.ui.orderFlowNavigation.enteredChiefComplaint);
  const rawDebouncedSearchText = useDebounce(searchText, 300);

  const debouncedSearchText = useAppSelector(
    (state) => state.ui.orderFlowNavigation.chiefComplaintDebouncedSearchText,
  );

  useEffect(() => {
    dispatch(uiActions.onChiefComplaintDebouncedSearchTextChange(rawDebouncedSearchText));
  }, [rawDebouncedSearchText, dispatch]);

  const [handleSaveChiefComplaints, { loading: isLoadingSaveChiefComplaints }] =
    useUpdateEncounterChiefComplaintsMutation();

  const { markCurrentStepComplete } = useAppUI();

  const isFollowUp = complaintType === 'follow-up';

  const { data: fetchedComplaintsData, loading: isLoadingGetChiefComplaint } =
    useGetChiefComplaintsQuery({
      variables: { contains: debouncedSearchText },
      skip: isFollowUp,
    });
  const fetchedComplaints = fetchedComplaintsData?.getChiefComplaints;

  const { data: medicalHistoryData, loading: isLoadingGetMedicalHistory } =
    useGetMedicalHistoryQuery({
      variables: { contains: debouncedSearchText, patientId: patientId || 0 },
      skip: !isFollowUp,
    });
  const medicalHistory = medicalHistoryData?.getMedicalHistory?.medicalHistory;

  const dropdownComplaints = isFollowUp ? getFollowUpItems(medicalHistory) : fetchedComplaints;

  async function saveChiefComplaints() {
    if (complaintNames && activePatientID && activeEncounterID) {
      const requestParams: ChiefComplaintsInput = {
        complaints: complaintNamesRef[0],
        patientId: activePatientID,
        encounterId: activeEncounterID,
      };
      handleSaveChiefComplaints({
        variables: requestParams,
        onError: () => {
          toast({
            id: 'submit-complaints-fail',
            title: 'Error',
            description: 'Something went wrong.',
            status: 'error',
          });
        },
        onCompleted: () => {
          toast({
            id: 'submit-complaints-success',
            title: 'Success',
            description: 'Your submission was successful.',
            status: 'success',
          });
          markCurrentStepComplete();
          nextStep();
        },
      });
    }
  }
  const searchRef = useRef<{ reset: () => void }>();
  function handleAddChiefComplaints() {
    dispatch(uiActions.addChiefComplaint(debouncedSearchText));
    searchRef.current?.reset();
  }

  const options = dropdownComplaints?.map((item) => {
    if (isMedicalHistory(item)) {
      return {
        label: item,
        value: item,
      };
    }

    const name = item;
    return {
      label: name,
      value: name,
    };
  });

  function handleOptionSelect(option: DropdownOption | undefined | null) {
    if (option != null) {
      dispatch(uiActions.addChiefComplaint(option.value));
    }
  }

  function handleAddCustomInputValueToList({ inputValue }: { inputValue: string }) {
    dispatch(uiActions.addChiefComplaint(inputValue));
  }

  return (
    <MAWorkFlowContentContainer>
      <VStack spacing='lg'>
        <Box width='100%'>
          <MAWorkFlowHeader>Chief Complaints</MAWorkFlowHeader>
        </Box>
        <HStack width='full' zIndex={1}>
          <InputGroup>
            <InputLeftAddon bgColor='white'>
              <RadioGroup
                onChange={(val: ComplaintType) => {
                  setComplaintType(val);
                }}
                value={complaintType}>
                <HStack spacing='lg'>
                  <Radio value='complaint'>Complaint</Radio>
                  <Radio value='follow-up'>Follow Up</Radio>
                </HStack>
              </RadioGroup>
            </InputLeftAddon>
            <AsyncSelectField
              ref={searchRef}
              handleOptionSelect={handleOptionSelect}
              onAddCustomInputValueToList={handleAddCustomInputValueToList}
              inputProps={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                placeholder: 'Add a complaint',
                autoFocus: true,
              }}
              dropdownItems={options}
              handleInputChange={(inputValue) => {
                inputValue !== undefined && dispatch(uiActions.onChiefComplaintChange(inputValue));
              }}
              loading={isLoadingGetChiefComplaint || isLoadingGetMedicalHistory}
            />
          </InputGroup>
          <Button onClick={handleAddChiefComplaints} disabled={!searchText} type='submit'>
            Add
          </Button>
        </HStack>
        <SaveAndWorklistButtons
          onClick={saveHandler}
          disabled={!complaintNames.length}
          isLoading={isLoadingSaveChiefComplaints || isLoadindgConstellation}>
          Save / Verify Complaints
        </SaveAndWorklistButtons>

        <WaitUntilLoaded loading={loadingExistingComplains}>
          <AddedChiefComplaints />
        </WaitUntilLoaded>
      </VStack>
    </MAWorkFlowContentContainer>
  );
}
